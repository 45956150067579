<template>
  <div>
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('date_from')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.dateFrom"
              :max="filter.dateTo"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('time_from')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.timeFrom"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('date_to')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.dateTo"
              :min="filter.dateFrom"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('time_to')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.timeTo"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('operator_account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.operatorAccount"
              autocomplete="off"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('related_account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.relatedAccount"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('type')"
              />
            </b-input-group-prepend>
            <div class="v-select-container">
              <multiselect
                v-model="filter.type"
                :options="typeOptions"
                :multiple="true"
                :close-on-select="false"
                :show-labels="false"
                :clear-on-select="false"
                :preserve-search="false"
                label="label"
                track-by="label"
                :preselect-first="false"
                :hide-selected="false"
                :internal-search="false"
                :limit="1"
                open-direction="bottom"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                >
                  <span
                    v-if="values.length &amp;&amp; !isOpen"
                    class="multiselect__single"
                  >
                    {{ values.length }} options selected
                  </span>
                </template>
              </multiselect>
            </div>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData(1)"
            v-text="$t('search')"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <div class="card">
        <div class="card-body">
          <div class="vgt-wrap">
            <div class="vgt-inner-wrap">
              <div class="vgt-responsive game-history-table">
                <table class="vgt-table bordered">
                  <thead>
                    <tr>
                      <th class="vgt-left-align">
                        <span v-text="$t('operator_account')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('type')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('related_account')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('time')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('ip')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('memo')" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in logs"
                      :key="item.id"
                    >
                      <!-- Operator Account -->
                      <td class="vgt-left-align">
                        <span v-text="item.operator_account" />
                      </td>
                      <!-- Type -->
                      <td class="vgt-left-align">
                        <span v-text="item.type" />
                      </td>
                      <!-- Related Account -->
                      <td class="vgt-left-align">
                        <span v-text="item.related_account" />
                      </td>
                      <!-- Time -->
                      <td class="vgt-left-align">
                        <span v-text="item.time" />
                      </td>
                      <!-- IP -->
                      <td class="vgt-left-align">
                        <span v-text="item.ip" />
                      </td>
                      <!-- Memo -->
                      <td class="vgt-left-align">
                        <span v-text="item.memo" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <custom-pagination
            v-model="filter.page"
            :total-items="totalItems"
            :per-page="perPage"
            :current-page="filter.page"
            @page-changed="loadData"
            @change-item-per-page="changeItemPerPage"
          />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormDatepicker,
  BFormTimepicker,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import Multiselect from 'vue-multiselect'

import Log from '@/models/Log'

import CustomPagination from '@/components/table/CustomPagination.vue'
import { EventBus } from '../../../eventbus'

export default {
  name: 'SettingLog',
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormDatepicker,
    BFormTimepicker,
    BButton,
    Multiselect,
    CustomPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      logs: [],
      perPage: 10,
      totalItems: 0,
      loading: false,
      typeOptions: [
        {
          label: 'Transfer to child',
          value: '1',
        },
        {
          label: 'Transfer from child',
          value: '2',
        },
        {
          label: 'Transfer from agent',
          value: '3',
        },
        {
          label: 'Transfer to agent',
          value: '4',
        },
        {
          label: 'Deposit from Acct. sys.',
          value: '5',
        },
        {
          label: 'Withdrawal from Acct. sys.',
          value: '6',
        },
        {
          label: 'Transfer to game',
          value: '7',
        },
        {
          label: 'Transfer from game',
          value: '8',
        },
        {
          label: 'Login Agent',
          value: '9',
        },
        {
          label: 'Modify Agent',
          value: '10',
        },
        {
          label: 'Create Sub Account',
          value: '11',
        },
        {
          label: 'Modify Password',
          value: '12',
        },
        {
          label: 'Create Agent',
          value: '13',
        },
        {
          label: 'Transcode management',
          value: '14',
        },
        {
          label: 'Modify Sub Account',
          value: '15',
        },
        {
          label: 'Disable / Enable',
          value: '16',
        },
        {
          label: 'Login Game',
          value: '17',
        },
        {
          label: 'Modify Password',
          value: '18',
        },
      ],
      filter: {
        operatorAccount: typeof this.$route.query.operatorAccount !== 'undefined' ? this.$route.query.operatorAccount : '',
        relatedAccount: typeof this.$route.query.relatedAccount !== 'undefined' ? this.$route.query.relatedAccount : '',
        dateFrom: typeof this.$route.query.dateFrom !== 'undefined' ? this.$route.query.dateFrom : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 518400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 518400000).getDate())}`,
        dateTo: typeof this.$route.query.dateTo !== 'undefined' ? this.$route.query.dateTo : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        timeFrom: typeof this.$route.query.timeFrom !== 'undefined' ? this.$route.query.timeFrom : '00:00:00',
        timeTo: typeof this.$route.query.timeTo !== 'undefined' ? this.$route.query.timeTo : '23:59:59',
        type: typeof this.$route.query.type !== 'undefined' ? [] : [],
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadAgent(id) {
      this.filter.agent = id
      this.filter.account = ''
      this.loadData(1)
    },
    async changeItemPerPage(itemPerPage) {
      this.perPage = itemPerPage
      this.loadData(1)
    },
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      this.filter.perPage = this.perPage
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        }
        if (typeof this.filter[e] === 'object') {
          if (this.filter[e].length > 0) {
            let newData = ''
            Object.keys(this.filter[e]).forEach(d => {
              newData += `${this.filter[e][d].value},`
            })
            searchParams[e] = newData
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Log.list(searchParams)
      this.logs = response.data.logs
      this.totalItems = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor

      this.loading = false
    },
  },
}
</script>

<style>
</style>
